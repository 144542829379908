var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-card",
            { attrs: { title: _vm.$t("lbl_unit_of_measurement") } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 10 } },
                    [
                      _c("InputSearch", {
                        on: { "input-search": _vm.reponseSearchInput }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 6 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.ResetFilter(true)
                            }
                          }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: {
                              cursor: "pointer",
                              fontsize: "large",
                              transform: "scaleX(-1)",
                              "-moz-transform": "scaleX(-1)",
                              "-webkit-transform": "scaleX(-1)",
                              "-ms-transform": "scaleX(-1)"
                            },
                            attrs: { type: "reload" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 8, align: "end" } },
                    [
                      _vm.authPrivilege.includes("create")
                        ? _c(
                            "a-button",
                            {
                              attrs: { icon: "plus", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.createNew()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("TableCustom", {
                        attrs: {
                          idtable: "table1",
                          "on-select-change": null,
                          "selected-row-keys": null,
                          "data-source": _vm.dataListUom.data,
                          columns: _vm.columnsTable,
                          scroll: { x: 700 },
                          paginationcustom: true,
                          "default-pagination": false
                        },
                        on: {
                          "on-delete": _vm.reponseDeleteTable,
                          "on-edit": _vm.reponseEditTable
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 18 } },
                    [
                      _c("Pagination", {
                        attrs: {
                          total: _vm.dataListUom.totalElements,
                          "page-size-set": _vm.limit,
                          "id-pagination": "pagination1"
                        },
                        on: {
                          "response-pagesize-change":
                            _vm.responsePageSizeChange,
                          "response-currentpage-change":
                            _vm.responseCurrentPageChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 6, align: "end" } },
                    [
                      _c("a-tag", { attrs: { color: "grey" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_total_found")) +
                            " : " +
                            _vm._s(_vm.dataListUom.totalElements) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            "mask-closable": false,
            width: 520,
            title: _vm.titlemodalOpen,
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "template",
            { slot: "contentmodal" },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    layout: "horizontal",
                    "label-align": "left",
                    form: _vm.form,
                    "label-col": { span: 5 },
                    "wrapper-col": { span: 18 }
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.formRules.unit.label } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.unit.decorator,
                            expression: "formRules.unit.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.unit.name,
                          placeholder: _vm.formRules.unit.placeholder,
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            return _vm.unitValueChange($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.formRules.name.label } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.name.decorator,
                            expression: "formRules.name.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.name.name,
                          placeholder: _vm.formRules.name.placeholder,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.formRules.description.label } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.description.name,
                          placeholder: _vm.formRules.description.placeholder,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.formRules.active.label } },
                    [
                      _c("a-switch", {
                        attrs: {
                          "checked-children": _vm.$t("lbl_active"),
                          "un-checked-children": _vm.$t("lbl_inactive"),
                          name: _vm.formRules.active.name
                        },
                        model: {
                          value: _vm.switchActive,
                          callback: function($$v) {
                            _vm.switchActive = $$v
                          },
                          expression: "switchActive"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footermodal" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    type: "primary",
                    loading: _vm.isFormSubmitted,
                    "html-type": "submit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm($event, _vm.typemodal)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }