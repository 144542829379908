


































































































































































import Vue from "vue";
import { settingsServices } from "@/services/settings.service";
import {
  ResponseListOfMasterUom,
  DataUnit,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  data() {
    return {
      dataListUom: {} as ResponseListOfMasterUom,
      direction: "" as string,
      typemodal: "" as string,
      titlemodalOpen: "" as string,
      switchActive: true as boolean,
      authPrivilege: [] as string[],
      selectedRowKeys: [] as number[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      dataListUnit: [] as DataUnit[],
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "uom" }),
      formRules: {
        unit: {
          label: this.$t("lbl_unit"),
          name: "unit",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        description: {
          label: this.$t("lbl_description"),
          name: "description",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        active: {
          label: this.$t("lbl_active"),
          name: "active",
          decorator: ["active"],
        },
        base: {
          label: this.$t("lbl_base_unit"),
          name: "base",
          decorator: ["base"],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: "100%",
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: true,
          width: "100%",
        },
        {
          title: this.$t("lbl_unit_detail"),
          dataIndex: "unit",
          key: "unit",
          sorter: true,
          width: "100%",
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          sorter: true,
          width: "100%",
          scopedSlots: { customRender: "isEnable" },
        },
      ] as any,
    };
  },
  mounted() {
    this.getListOfUom("");
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if (dataAuth.actions?.length && dataAuth?.name === "product-uom") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    handleSelectTable(value, key, col, recordOptions): void {
      this.dataListUnit[key] = { ...this.dataListUnit[key], [col]: value };
      this.dataListUnit = this.dataListUnit.slice();
    },
    handleInput(value, key, objectColInput) {
      this.dataListUnit[key][objectColInput.name] = value;
      this.dataListUnit = this.dataListUnit.slice();
    },
    unitValueChange(e): void {
      this.dataListUnit = [];
    },
    showConfirmation() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm"),
          content: this.$t("lbl_modal_delete_info", {
            count: this.selectedRowKeys.length,
          }),
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("lbl_modal_delete_error_description").toString(),
        });
      }
    },
    handleDeleteRow() {
      this.dataListUnit = this.dataListUnit.filter((data) => {
        return !this.selectedRowKeys.includes(data.key);
      });
      this.dataListUnit.forEach((data, index) => (data.key = index));
      this.dataListUnit = this.dataListUnit.slice();
      this.selectedRowKeys = [];
    },
    handleAddRow(): void {
      this.dataListUnit = [
        ...this.dataListUnit,
        {
          id: null,
          key: this.dataListUnit.length,
          baseUomName: this.form.getFieldValue("unit"),
          conversionRate: null,
          uomId: null,
        },
      ];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    reponseSearchInput(response: any): void {
      if (response) this.search = "name~*" + response + "*";
      else this.search = "";
      this.getListOfUom("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfUom("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfUom("");
    },
    reponseEditTable(response: any): void {
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_uom").toString();
      this.dataDetail = "/" + response.data.id;
      this.ResetFilter(false);
      this.getListOfUom("/" + response.data.id);
    },
    reponseDeleteTable(response: any): void {
      const path = "/" + response.data.id;
      settingsServices
        .deleteMasterUom(path)
        .then(() => {
          this.ResetFilter(false);
          this.getListOfUom("");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.form.resetFields();
      this.titlemodalOpen = this.$t("lbl_create_new_uom").toString();
      this.ResetFilter(false);
    },
    createNewUOM(datapost): void {
      settingsServices
        .createUom(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfUom("");
            this.form.resetFields();
            this.$message.success(this.$t("lbl_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateUOM(datapost): void {
      settingsServices
        .updateUom(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfUom("");
            this.form.resetFields();
            this.$message.success(this.$t("lbl_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e: Event, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const dataConversion = [] as any;
          const datapost = {
            active: this.switchActive,
            unit: values.unit,
            conversionCreateRequestDTO: [],
            name: values.name,
            description: values.description,
          };
          this.isFormSubmitted = true;
          this.dataListUnit.forEach((item) => {
            if (item.baseUomName && item.conversionRate && item.uomId)
              dataConversion.push({
                baseUomName: item.baseUomName,
                conversionRate: item.conversionRate,
                uomId: item.uomId,
              });
          });
          if (dataConversion.length > 0)
            datapost.conversionCreateRequestDTO = dataConversion;
          if (type === "create") {
            this.createNewUOM(datapost);
          } else {
            this.updateUOM(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
    getListOfUom(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      settingsServices
        .listOfMasterUom(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListUom = res;
          } else {
            this.dataListUnit = res.conversionResponseDTO
              ? res.conversionResponseDTO
              : [];
            const idtimeout = setTimeout(() => {
              this.form.setFieldsValue({
                unit: res.unit,
                name: res.name,
                description: res.description,
              });
              this.switchActive = res.active;
              clearTimeout(idtimeout);
            }, 200);
            this.modalOpen = true;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfUom("");
    },
  },
});
